.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-image: url('../public/navbar-gradient.jpg');
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-sizing: border-box;
  height: 90px;
}

.navbar-title {
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
}

.nav-menu {
  position: absolute;
  top: 70px;
  right: 20px;
  background-color: #333;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  background-color: rgba(51, 51, 51, 0.85);
}

.nav-menu button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  width: 100px;
}

.nav-menu button:hover {
  color: #ccc;
}

.hamburger-icon {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hamburger-icon img {
  width: 70px;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.hamburger-icon:hover img {
  content: url('../public/coffee_tilt.png');
  transform: scale(1.1);
  opacity: 0.9;
}

.favorites-link {
  color: #fff;
  margin-left: auto;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 25px;
}

.main-content {
  padding-top: 80px;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: space-between;
    align-items: flex-start;
  }

  .navbar-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .favorites-link {
    display: none;
  }

  .hamburger-icon {
    align-self: flex-end;
  }

  .nav-menu {
    width: 100px;
    right: 10%;
  }
  .mobile-menu{
    display: none;
  }
}