body.Game-page{
  overflow: hidden;
  height: 100vh;
  margin: 0;
}
.game-backdrop {
  background-color: #6F4E37; /* Coffee color for the backdrop */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

/* Game container styles */
.game-container {
  position: relative;
  overflow: hidden;
  border: 2px solid #FFD700;
  border-radius: 15px;
  background-color: #FAF3E0; /* Game container color */
}

/* Score styles */
.score {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

/* Coffee cup and beans */
.coffee-cup {
  position: absolute;
  bottom: 0;
  left: 50%; /* Centering the cup */
  transform: translateX(-10%); /* Adjust to center */
  width: 100px;
  height: 60px;
}

.coffee-bean {
  position: absolute;
  width: 50px;
  height: 50px;
}

/* Game Over Message */
.game-over-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 99, 71, 0.9); /* Slightly transparent red */
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  font-size: 32px; /* Larger font for emphasis */
  z-index: 10; /* Ensure it appears above other elements */
  text-align: center; /* Center align text */
}

/* Leaderboard styles */
.leaderboard {
  position: relative; /* Change to relative for better positioning */
  font-size: 18px;
  color: #333;
  background-color: #faf3e0;
  border: 2px solid #ffd700;
  border-radius: 10px;
  padding: 10px;
  margin-left: 20px; /* Add some space between the game container and leaderboard */
  z-index: 2;
}

.leaderboard h3 {
  margin: 0;
  font-size: 20px;
  text-align: center;
}

.leaderboard ul {
  list-style-type: none;
  padding: 0;
}

.leaderboard li {
  font-size: 18px;
}

/* Button Styles */
#start-game {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #FF6347; /* Tomato color */
  color: #faf3e9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

#start-game:hover {
  background-color: #FF4500; /* Darker shade on hover */
}