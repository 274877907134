.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #21150e;
  min-height: 100vh;
  margin: 0;
  font-family: "Kanit", sans-serif;
  background-image: url("../public/coffeebean.gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.main-content-home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 75px;
}

.left-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-basis: 30%;
  max-height: 70vh;
  overflow-y: auto;
}

.map-column {
  flex-basis: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 70vh;
  overflow: hidden;
}

.widget {
  background-image: linear-gradient(#3a2306,#5e4423,#8d7147,#c19d67);
  border: 4px, #ffffff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.widget:hover {
  color: #f9f8eb;
  background-image: linear-gradient(#c19d67, #8d7147, #5e4423,#3a2306);
}

.widget h2 {
  font-size: 20px;
}

.mobile-widget {
  background-image: linear-gradient(#3a2306,#5e4423,#8d7147,#c19d67);
  border: 4px, #ffffff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.mobile-widget:hover{
  color: #f9f8eb;
  background-image: linear-gradient(#c19d67, #8d7147, #5e4423,#3a2306);
}

.mobile-widget h2 {
  font-size: 20px;
}

.map-column > div {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .main-content-home {
    flex-direction: column;
  }

  .map-column {
    order: -1;
  }

  .map-column > div {
    height: 400px;
    margin-bottom: 20px;
  }

  .widget h2 {
    font-size: 18px;
  }

  .mobile-widget {
    display: none;
  }
}