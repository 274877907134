.quiz-content {
  background-color: #8B4513;
  margin-top: 90px;
  display: flex;
  justify-content: center;
}

.quiz-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  font-family: 'Book Antiqua', serif;
  text-align: center;
  margin-top: 6;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'Lucida Handwriting', cursive;
  color: saddlebrown;
}

.question {
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
}

#submit {
  background-color: #6a4f4b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

#submit:hover {
  background-color: #4f3735;
}

.bottom-image {
  width: 100px;
  height: auto;
  display: block;
  margin: 20px auto;
}

#result {
  margin-top: 20px;
  font-size: 1.2em;
}

.title-image {
  width: 80px;
  height: auto;
  background-color: brown;
  padding: 10px;
  border-radius: 10px;
}

.cat-image {
  position: absolute;
  width: 50px;
  height: auto;
  z-index: 0;
  animation: runAround 5s linear infinite;
}

@keyframes runAround {
  0% {
    top: 50%;
    right: 0%;
  }
  25% {
    top: 30%;
    right: 20%;
  }
  50% {
    top: 0%;
    left: 70%;
  }
  75% {
    top: 70%;
    left: 0%;
  }
  100% {
    top: 20%;
    left: 0%;
  }
}

.jolteon-gif {
  position: absolute;
  width: 50px;
  height: auto;
  z-index: 0;
  animation: runout 5s linear infinite;
}

@keyframes runout {
  0% {
    bottom: 10%;
    left: 0%;
  }
  25% {
    bottom: 30%;
    left: 20%;
  }
  50% {
    bottom: 0%;
    left: 25%;
  }
  75% {
    top: 70%;
    right: 100%;
  }
  100% {
    top: 30%;
    left: 50%;
  }
}

@media (max-width: 768px) {
  .quiz-container {
    width: 90%;
  }

  .title-image {
    width: 60px;
  }

  .input[type="text"] {
    width: 100%;
  }

  .cat-image {
    width: 40px;
  }
}
