.VS-page {
    overflow: hidden;
    height: 100vh;
    margin: 0;
}

.backdrop {
    margin: 0;
    height: 100vh;
    padding: 0;
    background-image: url('../public/virtualstudy/resources/images/sampleback9.jpg');
    background-position: center; 
    background-size: cover;
    /* background-image: radial-gradient(#4a2e0f, #b88b5e);  */
}



#virtual-header {
    /* background-color: #C4A484; */
    color: #331f16;
    font-size: x-large;
    text-align: left;
    margin-right: 80%;
    margin-top: 5%;
    margin-left: 3%;
    margin-bottom: 50px;
    padding: 30px 80px 15px 80px;
    width: 30%;
    word-wrap: break-word; 
    box-sizing: border-box; 
    float: left; 
}

#virtual-header h1 {
    font-family: serif;
    font-weight: 800;
    word-wrap: break-word; 
}
#virtual-header h4 {
    font-family: 'Courier New', Courier, monospace;
    word-wrap: break-word; 
}
#virtual-header h1, #virtual-header h4 {
    margin: 0;
    padding: 0;
    word-wrap: break-word; 
}

/* --------------TASKS LIST------------------- */

.to-do-list {
    position: absolute;
    top: 15%;
    right: 1.5%;
    background-color: #C4A484;
    width: 23%;
    max-height: 70%;
    padding: 10px;
    border-radius: 8%;
    font-size: 12px;
}

.to-do-list h2 {
    color: #5d422f;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 5px;
}

.to-do-list h2 img {
    width: 22px;
    margin-bottom: 5px;
    margin-left: 10px;
}

.add-row {
    display: flex;
    align-items: center;
    background-color: #F2D2BD;
    justify-content: space-between;
    border-radius: 30px;
    padding-left: 20px;
    margin-bottom: 15px;
}

#clear-log {
    border: none;
    font-size: 13px;
    outline: none;
    padding: 15px 20px;
    background-color: #8b0000; /* Dark red for differentiation */
    margin-right: 5px;
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    margin-left: 5px; /* Add some spacing */
  }

#clear-log:hover {
    background-color: #a52a2a; /* Lighter red for hover effect */
  }

#enter-task {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    padding: 10px;
    font-weight: 14px;
}

#add-button {
    border: none;
    outline: none;
    padding: 15px 20px;
    background-color: #6F4E37;
    color: #F2D2BD;
    font-size: 13px;
    cursor: pointer;
    border-radius: 40px;

}
/* Make separate container to house entries for scrolling with title+input still visible */
.entries-container {
    overflow:scroll;
    max-height: 120px;
    /* No need for vertical scrollbar, it will wrap */
    word-wrap: break-word; 
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* hidden but functional scrollbar */
.entries-container::-webkit-scrollbar {  
    display: none;
}

.entries-container li {
    list-style: none;
    font-size: 18px;
    padding: 12px 8px 12px 25px;
    user-select: none;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: left;
    flex-grow: 1;   
}
.task-text {
    margin-left: 0px;
    padding-left: 0px;
    display: flex;
    flex-grow: 1;
    overflow-wrap: anywhere;   
}

#list-container label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 5px;
    position: absolute;
    top: 8px;
    width: 20px;
    margin-right: 0;
    word-wrap: break-word;
}

#list-container label::after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 3px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 12px;
    word-wrap: break-word;
}

#list-container input[type="checkbox"] {
    visibility: hidden;
    margin-right: 0;
}
#list-container li.checked {
    text-decoration: line-through;
    color: #4f4141; /* Optional: change the text color for strikethrough tasks */
}

#list-container input[type="checkbox"]:checked + label {
    background-color: #41210f;
    border-color:#291502;
}
  
#list-container input[type="checkbox"]:checked + label:after {
    opacity: 1;
    text-decoration: line-through;

}


#delete-task {
    background:none;
    border-radius: 8px;
    align-self: flex-end;
    cursor: pointer;
    box-shadow: -0px 2px 0px 0px #190e07;
    margin: 5px;
    text-align: right;

}

#delete-task:active {
    box-shadow: -0px 2px 0px 0px #616161;
    transform: translateY(4px) translateX(-1px);
  }
#delete-task:disabled {
    background-color: #616161;
    box-shadow: -0px 2px 0px 0px #616161;
    transform: translateY(4px) translateX(-1px);
    cursor:not-allowed;
}

/* ------------------AUDIO SLIDERS-----------------*/
.slider-container{
    font-family: serif;
    /* color: rgb(244, 211, 160); */
    /* color:rgb(28, 50, 9); */
    /* font-style: oblique; */
    font-weight: bolder;
    font-size: 15.5px;
}

#AudioFilters{
    position: absolute;
    top: calc(3% + 350px);
    right: 3%;
    font-size: 15px;

}

.audio-slider {
    height: 3px;
    width: 300px;
    accent-color: #41210f;
}

.audio-slider::before {
    content: '';
    position:absolute;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-image: url("../public/virtualstudy/resources/images/Speaker_Icon.svg");
    background-size: cover;
    background-position: center;
    right: 100%;

}

/* ------------------SPOTIFY----------------- */

#Spotify {
    width: 400px;
    height: 100%;
    margin-left: 5%;
    bottom: 0;
}

#header2{
    background-color: azure;
}

.main-content {
    padding-top: 100px;
}

/* TIMER BUTTON  */
.timer-display {
    padding: 5px;
    margin: 5px;
}
.timer-buttons {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timer-buttons button {
    background-color: #ad8866;
    border-radius: 8px;
    border-width: 2px;
    margin: 5px;
    cursor: pointer;
}

.timer-buttons button:active {
    box-shadow: -0px 2px 0px 0px #616161;
    transform: translateY(4px) translateX(-1px);
  }
.timer-buttons button:disabled {
    background-color: #616161;
    box-shadow: -0px 2px 0px 0px #616161;
    transform: translateY(4px) translateX(-1px);
    cursor:not-allowed;
}
.close-modal {
    background-color: #5d422f;
    color: #F2D2BD;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 10px;
}

#close-timer {
    margin-top: 40px;
}

#close-timer:active {
    box-shadow: -0px 2px 0px 0px #616161;
    transform: translateY(4px) translateX(-1px);
  }
#close-timer:disabled {
    background-color: #616161;
    box-shadow: -0px 2px 0px 0px #616161;
    transform: translateY(4px) translateX(-1px);
    cursor:not-allowed;
}
.virtual-buttons {
    position: absolute; 
    bottom: 10%; 
    left: 5%;
    position: absolute; 
    bottom: 10%; 
    left: 5%;
    background-color: lightgray;
    color: #F2D2BD;
    border: 0;
    padding: 25px 25px;
    margin: 8px;
    border-radius: 50px;
    height: 30px;
    width: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    
}

.virtual-buttons:hover {
    background-color: #291502;
    cursor: pointer;
    border:1px solid #333
}

#open-timer {
    background-image: url("../public/virtualstudy/resources/images/clock.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    right: 0;
    bottom: 10%; 
    left: 5%;
    
}

#open-info {
    background-image: url("../public/virtualstudy/resources/images/white-info.svg");
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 10%;
}

#close-info {
    display: flex;
    justify-self: end;
    position: relative;
    align-items: center;
    margin-right: 5px;
    padding-left: 8px;
    padding-right: 15px;
    width: 20px;  
    height:20px;
}

@keyframes wave {
    0% {opacity: 0; transform: translateY(15px)}
    50% {opacity: 1; transform: translateY(0)}
    100% {opacity: 0; transform: translateY(-15px)}
}

.info-coffee {
    position: relative;
    margin-left: 38%;
    scale: 70%;
    bottom: 15px;
}

.info-coffee ul {
    margin: 0 0 10px 0;
    padding: 0;
    list-style: none;
    width: 60px;
    text-align: center;
    animation: wave 2.5s infinite linear;
    animation-fill-mode: forwards;
}

.info-coffee li {
  display: inline-block;
  background: #FAF1D9;
  height: 30px;
  width: 9px;
  border-radius: 0 100%;
  transform: rotate(12deg);
}

.info-cup {
  background: #5a8067;
  width: 60px;
  height: 54px;
  border-radius: 0 0 50% 50%;
  position: relative;
}

.info-cup:before {
  content: "";
  position: absolute;
  width: 66px;
  height: 20px;
  border-radius: 50%;
  background: inherit;
  left: -3px;
  top: -10px;
}

.info-cup:after {
  content: "";
  position: absolute;
  width: 56px;
  height: 12px;
  border-radius: 50%;
  background: #3A2F28;
  left: 2px;
  top: -6px;
}

.info-coffee span {
  background: #5a8067;
  width: 13px;
  height: 20px;
  position: absolute; 
  right: -13px;
  top: 10px;
  border-radius: 0 50% 50% 0;
}
.info-coffee span:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  background: #375943;
  width: 10px;
  height: 12px;
  border-radius: 0 50% 50% 0;
}
.info-coffee span:after {
  content: "";
  position: absolute;
  top: 40px;
  left: -50px;
  background: #5a8067;
  width: 40px;
  height: 8px;
  border-radius: 50%;
}
.modal-container {
    /* display: none; */
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity 0 so its still on top but hidden. pointer events none so we can click thru it */
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.modal-container.show {
    pointer-events: auto;
    opacity: 1;
}

.modal {
    background-color: #C4A484;
    padding: 35px 35px;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 650px;
    height: 35vh;
    max-width: 100%;
    text-align: center;
}

.modal h1 {
    margin: 0;
    padding: 15px 20px;
}
