.register-background {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: "Kanit", sans-serif;
    font-weight: 100;
    font-style: normal;
    background-image: url("../public/coffeebean1.gif");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.link-button {
    background: none;
    border: none;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
}
  
.link-button:hover {
    text-decoration: none;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
}

.form-container {
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    background-color: #381c0a;
    text-align: center;
    box-sizing: border-box;
}

.hidden {
    display: none;
}

.headerreg {
    margin-bottom: 20px;
    color: #fff;
    font-size: 24px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin: 5px;
    color: black;
    font-size: medium;
    margin-bottom: -10px;
}

input[type="text"],
input[type="password"],
input[type="email"] {
    width: calc(100% - 22px);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: black;
    margin: 10px 0;
    box-sizing: border-box;
}

.subbutton{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #C0997F;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.welcome-container {
    text-align: center;
    padding: 50px;
    color: white;
}

.welcome-header {
    font-size: 3em;
    margin-bottom: 50px;
}

.options-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px;
    gap: 100px;
}

.option-box {
    background-color: #381c0a;
    padding: 30px 50px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 50px;
    width: 200px;
    justify-content: center;
    align-items: center;
}

.option-box:hover {
    background-color: hsl(14, 15%, 42%);
}

.option-box h2 {
    color: white;
    font-size: 20px;
}

.back-button {
    background-color: #C0997F;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    width: 150px;
}

.back-button:hover {
    background-color: hsl(14, 15%, 42%);
}

.login-color {
    color:white;
}

.reg-notification {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #9f7676;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-size: 1rem;
    color: #333;
    height: 100px;
}


@media only screen and (max-width: 768px) {
    .login-container {
        padding: 20px;
    }

    .form-container {
        width: 100%;
        padding: 15px;
    }

    .headerreg {
        font-size: 20px;
    }

    .options-container {
        flex-direction: column;
        margin: 50px 0;
        gap: 50px;
        margin-bottom: 20px;
    }

    .option-box {
        width: 100%;
        padding: 20px 30px;
    }

    .subbutton {
        padding: 12px;
    }

    .back-button {
        width: 50%;
        padding: 10px;
    }

    .welcome-header {
        font-size: 2em;
    }

    .reg-notification {
        height: 80px;
    }
}

