.profile-container {
  width: 600px;
  height: 50%;
  margin: 300px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.profile-info {
  margin-bottom: 15px;
}

.profile-info label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.profile-info input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.profile-info p {
  font-size: 16px;
  padding: 8px;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.profile-actions {
  text-align: center;
}

.profile-actions button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s ease;
}

.profile-actions button:hover {
  background-color: #0056b3;
}

.profile-actions button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
