.coffee-news-container {
    font-family: 'Times New Roman', Times, serif;
    color: #fff;
    padding: 20px;
    background-color: #6f4f28;
    text-align: center;
    margin: 0;
}

.coffee-news-article {
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #3e2d19;
    transition: background-color 0.3s ease;
    border-radius: 0;
}

.coffee-news-article:hover {
    background-color: #f1f1f1;
}


.coffee-news-article h2 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.5em;
    margin: 0;
    color: #fff;
}

.coffee-news-article p {
    font-size: 1em;
    color: #bbb;
    line-height: 1.5;
}

.coffee-news-article a {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
}

.coffee-news-article a:hover {
    text-decoration: underline;
}

/* Additional styling to remove default list style, if used */

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Media Queries for Responsiveness */
@media only screen and (max-width: 768px) {
    .coffee-news-container {
        padding: 15px;
    }

    .coffee-news-article {
        padding: 15px;
    }

    .coffee-news-article h2 {
        font-size: 1.3em;
    }

    .coffee-news-article p {
        font-size: 0.9em;
    }

    .coffee-news-article a {
        font-size: 0.9em;
    }

    ul {
        padding-left: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .coffee-news-container {
        padding: 10px;
    }

    .coffee-news-article {
        padding: 10px;
    }

    .coffee-news-article h2 {
        font-size: 1.1em;
    }

    .coffee-news-article p {
        font-size: 0.8em;
    }

    .coffee-news-article a {
        font-size: 0.8em;
    }

    ul {
        padding-left: 10px;
    }
}
