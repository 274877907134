body {
    background-color: #6F4E37;
}
.container {
    text-align: center;
    position: relative;
    z-index: 2;
}
.coffee-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 0;
    overflow: hidden;
}
header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

h1 {
    font-size: 2em;
    font-family: 'Cursive', sans-serif;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.generation-box {
    width: 300px;
    height: 400px;
    background-color: #f7e7d2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 1.2em;
    border-radius: 10px;
}

.generate-button {
    padding: 10px 20px;
    /*background-color: #29241b;*/
    background-image: linear-gradient(#402d20, #634632);
    border: none;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    width: 250px;
    margin: 5px;;
}

.generate-button:hover {
    background-image: linear-gradient(hsl(34, 59%, 82%), #f7e7d2);
    color: black;
    transform: scale(1.2);
}

.main-content {
    padding-top: 80px;
}
