.panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  background-color: #fff;
  box-shadow: -3px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 90px;
}

.panelheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.closeButton {
  font-size: 20px;
  cursor: pointer;
  color: black;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  position: absolute;
  top: 10px;
  right: 10px;
}

.info-content {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.directionsLink {
  display: block;
  padding: 10px;
  background-color: #007BFF;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  width: 80%;
  margin: 10px auto;
}

.directionsLink:hover {
  background-color: #0056b3;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.button-style {
  padding: 10px;
  background-color: #007BFF;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  width: 80%;
  margin: 10px auto;
}

.button-style:hover {
  background-color: #0056b3;
}

.info-notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.info-success {
  background-color: #d4edda;
  color: #155724;
}

.info-error {
  background-color: #f8d7da;
  color: #721c24;
}

.info-notification-close {
  background: none;
  border: none;
  color: inherit;
  font-size: 1.2em;
  cursor: pointer;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.info-notification-close:hover {
  color: #d9534f;
}

@media only screen and (max-width: 768px) {
  .panel {
    width: 100%;
    height: auto;
    bottom: 0;
    top: auto;
    box-shadow: none;
    border-radius: 10px 10px 0 0;
    padding: 15px;
    margin-top: 0;
  }

  .panelheader {
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
  }

  .closeButton {
    font-size: 18px;
    top: 5px;
    right: 10px;
  }

  .info-content {
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .directionsLink {
    width: 100%;
    text-align: center;
  }

  .buttons button {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .panel {
    padding: 10px;
  }

  .panelheader {
    margin-bottom: 10px;
  }

  .closeButton {
    font-size: 16px;
    top: 5px;
    right: 10px;
  }

  .directionsLink {
    padding: 12px;
  }

  .buttons button {
    padding: 12px;
  }
}
