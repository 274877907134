.favorites-page {
  font-family: 'Times New Roman', Times, serif;
  color: #fff;
  padding: 20px;
  background-color: #3e2d19;
  text-align: center;
  margin: 0;
}

.favorite-item {
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #3e2d19;
  transition: background-color 0.3s ease;
  border-radius: 0;
}

.favorite-item:hover {
  background-color: #634828;
}

.favorite-item h2 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.5em;
  margin: 0;
  color: #fff;
}

.favorite-item p {
  font-size: 1em;
  color: #bbb;
  line-height: 1.5;
}

.directions-button, .remove-favorite-button {
  margin-top: 10px;
  margin: 5px;
  padding: 10px;
  background-color: #6f4f28;
  border: none;
  color: white;
  font-size: 1em;
  border-radius: 8px;
  cursor: pointer;
}

.directions-button:hover, .remove-favorite-button:hover {
  background-color: #3e2d19;
}

.favorites-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.notification-close {
  background: none;
  border: none;
  color: #155724;
  font-size: 1.2em;
  cursor: pointer;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  size: 3px;
}

.notification-close:hover {
  color: #d9534f;
}



@media only screen and (max-width: 768px) {
  .favorites-page {
    padding: 15px;
  }

  .favorite-item {
    padding: 15px;
  }

  .favorite-item h2 {
    font-size: 1.3em;
  }

  .favorite-item p {
    font-size: 0.9em;
  }

  .favorite-item a {
    font-size: 0.9em;
  }

  .directions-button, .remove-favorite-button {
    font-size: 0.9em;
  }

  .favorites-list {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .favorites-page {
    padding: 10px;
  }

  .favorite-item {
    padding: 10px;
  }

  .favorite-item h2 {
    font-size: 1.1em;
  }

  .favorite-item p {
    font-size: 0.8em;
  }

  .favorite-item a {
    font-size: 0.8em;
  }

  .directions-button, .remove-favorite-button {
    font-size: 0.8em;
  }

  .favorites-list {
    padding-left: 10px;
  }
}