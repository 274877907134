.map-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
}

.center-map-button {
  position: absolute;
  bottom: 15px;
  left: 15px;
  padding: 10px 15px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
  width: 120px;
  text-align: center;
}

.center-map-button:hover{
  background-color: #007bffbd;
}

.main-content {
  padding-top: 80px;
}

@media only screen and (max-width: 768px) {
  .center-map-button {
    display: none;
  }

  .loading-container{
    display: flex;
  }
}